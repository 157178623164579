<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo-psychomeasure-icon.png')"
              max-height="64px"
              max-width="64px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold title-header">
              <span class="light">Psycho</span>measure
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form @keyup.enter="submit">
            <v-text-field
              @keyup.enter="submit"
              v-model="email"
              outlined
              name="login"
              label="Email"
              placeholder="john@example.com"
              type="email"
              hide-details
              class="mb-3"
              required
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>

            <v-text-field
              @keyup.enter="submit"
              v-model="password"
              outlined
              id="password"
              name="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :error-messages="passwordErrors"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            ></v-text-field>
            <div v-if="loginError">
              <v-alert
                :value="loginError"
                transition="fade-transition"
                type="error"
              >
                Incorrect email or password
              </v-alert>
            </div>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox>

              <!-- forgot link -->
              <router-link :to="{ name: 'recover-password' }">
                Forgot Password?
              </router-link>
            </div>

            <v-btn block color="primary" class="mt-6" @click.prevent="submit">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'register' }">
            Create an account
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="
        require(`@/assets/images/misc/mask-${
          $vuetify.theme.dark ? 'dark' : 'light'
        }.png`)
      "
    />

    <!-- tree -->
    <!--
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
    -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required, email, minLength } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
  },
  data() {
    return {
      email: "",
      password: "",
      isPasswordVisible: false,
      icons: { mdiEyeOffOutline, mdiEyeOutline },
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push("Must be valid e-mail")
      !this.$v.email.required && errors.push("E-mail is required.")
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push("Password is required.")
      return errors
    },
    ...mapState({ loginError: state => state.users.logInError }),
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.login({ username: this.email, password: this.password })
      }
    },
    ...mapActions("users", ["login"]),
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
